<template>
  <main class="main profile-page sign-modal reg-modal page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <div class="section hero-section">
        <img src="./../img/hero.jpg" class="bg"/>
        <div class="wrapper home">
          <div class="title big">My Profile</div>
        </div>
      </div>
      <section class="section store-section">
        <img src="./../img/section6.svg" class="bg"/>
        <div class="wrapper container">
          <div class="profile-form">
            <div class="profile-form-wrapper">
              <div class="wrapper form-fields form-fields-wrapper">
                <div class="title small">Personal Information</div>
                <div>
                  <label class="input-container">
                    <div class="desc"><span class="red">*</span> First name:</div>
                    <input type="text" placeholder="First name" v-model="name"/>
                  </label>
                  <label class="input-container">
                    <div class="desc"><span class="red">*</span> Last name:</div>
                    <input type="text" placeholder="Last name" v-model="surname"/>
                  </label>
                  <label class="input-container">
                    <div class="desc"><span class="red">*</span> E-mail:</div>
                    <input type="email" placeholder="E-mail" v-model="email"/>
                  </label>
                  <label class="input-container">
                    <div class="desc"><span class="red">*</span> Nickname:</div>
                    <input type="text" placeholder="Nickname" v-model="nickname"/>
                  </label>
                  <label class="input-container">
                    <div class="desc"><span class="red">*</span> Phone:</div>
                    <input type="tel" placeholder="Phone" v-model="phone"/>
                  </label>
                </div>
                <button class="button" @click="updateProfileData">
                  <span>Save</span>
                </button>
                <transition name="fade">
                  <div class="desc red" v-if="profileUpdateErrorMessaage">{{profileUpdateErrorMessaage}}</div>
                </transition>
                <transition name="fade">
                  <div class="desc green" v-if="profileUpdateSuccessMessaage">{{profileUpdateSuccessMessaage}}</div>
                </transition>
              </div>
              <div class="wrapper form-fields form-fields-wrapper">
                <div class="title small">Change Password</div>
                <label class="input-container">
                  <div class="desc"><span class="red">*</span> Current password:</div>
                  <input type="text" placeholder="Current password" v-model="curPass"/>
                </label>
                <label class="input-container">
                  <div class="desc"><span class="red">*</span> New password:</div>
                  <input type="text" placeholder="New password" v-model="newPass"/>
                </label>
                <label class="input-container">
                  <div class="desc"><span class="red">*</span> Confirm password:</div>
                  <input type="text" placeholder="Confirm password" v-model="repeatPass"/>
                </label>
                <div class="pass-rules">
                  <div class="desc bold">Password must contain:</div>
                  <div class="desc">- At least 6 characters, 1 upper case letter (A-Z), 1 lower case letter (a-z), 1 number (0-9)</div>
                </div>
                <button class="button" @click="updatePassword">
                  <span>Save</span>
                </button>
                <transition name="fade">
                  <div class="desc red" v-if="passUpdateErrorMessaage">{{passUpdateErrorMessaage}}</div>
                </transition>
                <transition name="fade">
                  <div class="desc green" v-if="passUpdateSuccessMessaage">{{passUpdateSuccessMessaage}}</div>
                </transition>
              </div>
            </div>
          </div>
          <div class="profile-table" v-if="$parent.orderHistory">
            <div class="profile-table-wrapper profile-form-wrapper">
              <div class="title small">Your Orders</div>
              <div class="table-container">
                <table>
                  <thead>
                    <tr>
                      <td>ID</td>
                      <td>Date/time</td>
                      <td>Item name</td>
                      <td>Total</td>
                      <td>Status</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item,i) in $parent.orderHistory" :key="i">
                      <td>{{item.id}}</td>
                      <td>{{$parent.formatDate(item.created_at)}}</td>
                      <td>{{item.title}}</td>
                      <td>{{item.sum}} {{$parent.currency}}</td>
                      <td :class="[{'green': item.status == 'Accepted'}, {'white': item.status == 'Processing'}]">{{item.status}}</td>
                      <!-- <td>
                        <a class="offer-modal-open" @click="$emit('openOfferDetailsModal', item)">View</a>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="section store-section-2">
        <img src="./../img/section7.png" class="bg"/>
        <div class="wrapper">
          <div class="title small server-status"><span>S</span>ERVER <span>S</span>TATUS: <span class="green">ONLINE</span></div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Profile',
  props: [],
  components: {
  },
  data: function() {
    return {
      email: '',
      name: '',
      surname: '',
      nickname: '',
      phone: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      curPass: '',
      newPass: '',
      repeatPass: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: ''
    }
  },
  mounted() {
    this.$http.get(process.env.VUE_APP_API + 'user/profile')
    .then((res) => {
        this.email = res.data.email;
        this.name = res.data.name;
        this.surname = res.data.surname;
        this.phone = res.data.phone;
        this.nickname = res.data.nickname;
    })
    .catch((res) => {
      if(res.response.status === 401) {
        this.$parent.openSignInModal();
      }
    })
  },
  methods: {
    openOfferDetailsModal() {
      this.$emit('openOfferDetailsModal')
    },
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "phone": this.phone,
        "surname": this.surname,
        "nickname": this.nickname
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
      .then((res) => {
        if (res.data.status == "OK") {
          this.profileUpdateSuccessMessaage = 'Success';
          setTimeout(() => {
            this.profileUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
        this.profileUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.profileUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
      .then((res) => {
        if (res.data.status == "OK") {
          this.passUpdateSuccessMessaage = 'Success';
          this.curPass = '',
          this.newPass = '',
          this.repeatPass = '';
          setTimeout(() => {
            this.passUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
        this.passUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.passUpdateErrorMessaage = '';
        }, 3000);
      })
    }
  }
}
</script>