<template>
	<header class="header">
    <img src="./../img/header.svg" class="bg"/>
      <div class="wrapper">
        <div class="left">
          <router-link to="/" class="logo">
            <img src="./../img/logo.svg" class="img"/>
          </router-link>
        </div>
        <ul class="nav">
            <li v-for="item in $parent.nav" class="nav__item" :key="item.title">
              <router-link class="title small" :to="item.path" v-html="$parent.wrapFirstLetterInSpan(item.title)"></router-link>
            </li>
        </ul>
        <div class="right">
          <router-link to="/profile" class="button link-container dropdown-open" v-if="$parent.isAuth">
            <img src="./../img/user.svg" class="img"/>
          </router-link>
          <router-link to="/cart" class="cart-icon button" v-if="$parent.isAuth">
            <div v-if="$parent.cartContents.length" class="indicator">
              {{$parent.cartContents.length}}
            </div>
          </router-link>
          <div class="link-container" v-else>
            <div class="link hover-link" @click="openSignIn()">
              <span class="desc sign-in-desc">Log in</span>
            </div>
            <div class="link hover-link" @click="openSignUp()">
              <span class="desc sign-up-desc">Sign Up</span>
            </div>
          </div>
        </div>
      </div>
  </header>
</template>

<script>

export default {
	name: 'Header',
  props: [],
	data: function() {
		return {
		}
	},
	methods: {
    openSignIn() {
      this.$router.push({path: '/login'});
    },
    openSignUp() {
      this.$router.push({path: '/registration'});
    }
	}
}
</script>
