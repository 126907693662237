<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <img src="./../img/hero.jpg" class="bg"/>
        <div class="wrapper home">
          <div class="title big">WELCOME</div>
        </div>
      </div>
      <div class="section section-2">
        <img src="./../img/section2.svg" class="bg"/>
        <div class="wrapper">
          <div class="title title-main">
            Discover an outstanding unofficial Minecraft survival server boasting mods catering to every preference! Dive into an exhilarating aquaworld and establish clans in brand new realms.
          </div>
          <button @click="copyIp" class="button">{{ipCopied ? 'IP copied' : 'CONNECT NOW'}}</button>
          <div class="desc desc-main">
            Excellent unofficial Minecraft survival server with mods for every taste!
          </div>
        </div>
      </div>
      <div class="section section-7" v-if="$parent.newOffer && $parent.newOffer.length">
        <img src="./../img/section3.svg" class="bg"/>
        <div class="wrapper">
          <div class="title">
            <span>NEW</span> OFFER 
          </div>
          <div class="new-offer-slider list products">
            <slick ref="slick"  :options="slickOptions" class="slider products" @swipe="handleSwipe">
              <div class="item" v-for="(item, index) in $parent.newOffer" :key="index">
                <ProductCard :item="item" :addToCartChosenItem="addToCartChosenItem" :sliderIsClickble="sliderIsClickble" @goToProduct="goToProduct" :currency="$parent.currency" @addToCart="addToCart"/>
              </div>
            </slick>
          </div>
          <div class="desc desc-main">see more products in our store</div>
          <router-link :to="'/store' + activeCategory.url" class="button button-main">
            Store
          </router-link>
        </div>
      </div>
      <div class="section section-3" v-if="$parent.faq && $parent.faq.length">
        <img src="./../img/section4.png" class="bg"/>
        <div class="wrapper">
          <div class="title title-main">FAQ</div>
          <div class="faq-container">
            <img src="./../img/dolphin.png" class="dolphin"/>
            <div class="faq-wrapper">
              <div class="faq-tabs">
                <div class="item" v-for="(item, index) in $parent.faq.slice(0,4)" :key="index">
                  <div :class="['panel', {'active': isActivePanel(index)}]" @click="togglePanel(index)">
                    <div class="title small">{{ item.title }}</div>
                  </div>
                </div>
              </div>
              <div class="faq-preview" v-if="$parent.faq[activePanels[0]]">
                <img src="./../img/fish.png" class="fish"/>
                <div class="desc">
                  <b>{{ $parent.faq[activePanels[0]].title }}</b><br>
                  {{ $parent.faq[activePanels[0]].content }}
                </div>
                <div class="arrows">
                  <div class="arrow" @click="prevPanel(activePanels[0])"></div>
                  <div class="arrow" @click="nextPanel(activePanels[0])"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-8">
        <img src="./../img/decor1.svg" class="decor1"/>
        <img src="./../img/decor2.svg" class="decor2"/>
        <img src="./../img/section5.svg" class="bg"/>
        <div class="wrapper">
          <div class="left">
            <div class="title">JOIN <br>US!</div>
          </div>
          <div class="right">
            <div class="title">
              Ready to dive into the depths? Equip yourself, rally your friends, and let's embark on this adventure...
            </div>
            <div class="desc desc-main">
              To access our game server, ensure you have a legitimate copy of Minecraft (version 1.20.4) installed on your computer. Once set up, enter our server IP: {{ ip }} into the server launcher and hit 'Join!'
            </div>
            <button @click="copyIp" class="button">{{ipCopied ? 'IP copied' : 'CONNECT NOW'}}</button>
            <div class="trustpilot">
              <div class="desc">
                See our <b>15</b> reviews on
              </div>
              <img src="./../img/trustpilot.svg" class="img"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import ProductCard from '../components/ProductCard.vue'
import Slick from 'vue-slick';

export default {
  name: 'Home',
  props: ['activeCategory', 'addToCartChosenItem'],
  components: {
    ProductCard,
    Slick
  },
  data: function() {
    return {
      activePanels: [],
      ipCopied: false,
      ip: '62.72.32.143',
      sliderIsClickble: true,
      slickOptions: {
        slidesToScroll: 5,
        slidesToShow: 5,
        arrows: true,
        infinite: false,
        variableWidth: false,
        dots: false,
        responsive: [
          {
            breakpoint: 1601,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
            }
          },
          {
            breakpoint: 1201,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            }
          },
          {
            breakpoint: 901,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 601,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      },
    }
  },
  mounted() {
    this.togglePanel(0);
  },
  methods: {
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    handleSwipe() {
      let self = this;
      self.sliderIsClickble = false;
      setTimeout(()=> {
        self.sliderIsClickble = true;
      }, 300)
    },
    copyIp() {
      let self = this;
      var textarea = document.createElement("textarea");
      textarea.value = self.ip;
      document.body.appendChild(textarea);

      textarea.select();
      document.execCommand("copy");

      document.body.removeChild(textarea);
      self.ipCopied = true;
      
      setTimeout(()=> {
        self.ipCopied = false;
      }, 2000)
    },
    togglePanel(index) {
        this.activePanels = []
        this.activePanels.push(index)
    },
    nextPanel(item) {
      if (item < 3) {
        this.togglePanel(item + 1);
      } else {
        this.togglePanel(0)
      }
    },
    prevPanel(item) {
      if (item > 0) {
        this.togglePanel(item - 1);
      } else {
        this.togglePanel(3)
      }
    },
    isActivePanel(index) {
      return this.activePanels.includes(index)
    },
  }
}
</script>