<template>
  <div class="modal sign-modal recover-modal page-inside">
    <div class="main-wrapper">
      <div class="section hero-section">
        <img src="./../img/hero.jpg" class="bg"/>
        <div class="wrapper home">
          <div class="title big">Recover</div>
        </div>
      </div>
      <section class="section store-section">
        <img src="./../img/section6.svg" class="bg"/>
        <div class="wrapper">
          <div class="container">
            <div class="form">
              <div class="form-wrapper">
                <div class="form-fields login-content">
                  <div class="form-fields-wrapper">
                    <label>
                      <div class="desc">E-mail:</div>
                      <input type="email" placeholder="Email" v-model="email"/>
                    </label>
                    <div class="cta-container">
                      <button class="button" @click="submitForgotPass">
                        <span>Recover</span>
                      </button>
                    </div>
                    <transition name="fade">
                      <div v-if="$parent.successMessage" class="desc green">{{$parent.successMessage}}</div>
                    </transition>
                    <transition name="slide">
                      <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                    </transition>
                  </div>
                </div>
                <div class="modal-bottom">
                  <div class="title">Have an account?</div>
                  <div class="desc">Please login with your personal info</div>
                  <div class="button switch-login" @click="switchToLogin()">Login</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Recover',
  props: [],
  components: {
  },
  data: function() {
    return {
      email: ''
    }
  },
  mounted() {
    
  },
  methods: {
    switchToforgotPass() {
      this.$parent.clearError();
      this.$parent.openRecover();
    },
    switchToLogin() {
      this.$parent.clearError();
      this.$parent.openSignIn();
    },
    submitForgotPass() {
      let data = {
        "email": this.email
      }
      this.$emit('forgotPass', data)
    },
  }
}
</script>