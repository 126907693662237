<template>
  <main class="main cart-page page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <div class="section hero-section">
        <img src="./../img/hero.jpg" class="bg"/>
        <div class="wrapper home">
          <div class="title big">CART</div>
        </div>
      </div>
      <section class="section store-section">
        <img src="./../img/section6.svg" class="bg"/>
        <div class="wrapper">
          <div class="desc empty-desc" v-if="!$parent.cartContents.length">
            Cart is empty!
          </div>
          <div class="left" v-if="$parent.cartContents.length">
            <div class="title title-main"><span>Y</span>OUR BASKET</div>
            <div class="product-list">
              <div class="item" v-for="(item, i) in $parent.cartContents" :key="i">
                <div class="preview">
                  <img :src="imgDomain + item.item.img_url" class="img"/>
                </div>
                <div class="title name">{{item.item.title}}</div>
                <div class="count">
                  <div class="count__item minus" @click="$parent.removeOneFromCart(item, item.item_id)"></div>
                  <input type="number" value="1" v-model="item.count" step="1"/>
                  <div class="count__item plus" @click="$parent.addToCart(item.item, item.item_id)"></div>
                </div>
                <div class="price">{{item.item.price}} <span class="currency">{{$parent.currency}}</span></div>
                <div class="delete" @click="$parent.removeFromCart(item.item, item.item_id)">
                  <img src="./../img/bin.svg" class="img"/>
                </div>
              </div>
            </div>
          </div>
          <div class="right" v-if="$parent.cartContents.length">
            <div class="wrapper">
              <div class="title title-main"><span>T</span>otal</div>
              <div class="row">
                <div class="title">{{$parent.totalSum}} <span class="currency">{{$parent.currency}}</span></div>
              </div>
              <div class="button-container">
                <button @click="toOrder" class="button">
                  <span>PROCEED TO CHECKOUT</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="section store-section-2">
        <img src="./../img/section7.png" class="bg"/>
        <div class="wrapper">
          <div class="title small server-status"><span>S</span>ERVER <span>S</span>TATUS: <span class="green">ONLINE</span></div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Cart',
  props: [],
  components: {
  },
  data: function() {
    return {
      imgDomain: ''
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    toOrder() {
      this.$router.push({ path: '/order' })
    }
  }
}
</script>