<template>
	<footer class="footer">
    <div class="footer-section">
      <div class="wrapper">
        <div class="left">
          <router-link to="/" class="logo">
            <img src="./../img/logo.svg" class="img"/>
          </router-link>
          <div class="desc" v-if="settings">{{ settings.requisites }}</div>
        </div>
        <div class="center-one">
          <ul class="nav">
            <template  v-for="item in textPageList">
              <li class="nav__item" :key="item.id">
                <a class="title" @click="goToPage(item)" v-html="$parent.wrapFirstLetterInSpan(item.title)"></a>
              </li>
            </template>
            <li v-for="item in $parent.nav" class="nav__item" :key="item.title">
              <router-link class="title small" :to="item.path" v-html="$parent.wrapFirstLetterInSpan(item.title)"></router-link>
            </li>
          </ul>
        </div>
        <div class="center-two">
          <div class="list" v-if="socialLinks && socialLinks.length">
            <a v-for="link in socialLinks"  target="_blank" :href="link.link" :key="link.img">
              <img :src="link.img" class="img"/>
            </a>
          </div>
        </div>
        <div class="right">
          <div class="list">
            <img src="./../img/visa.svg" class="img visa"/>
            <img src="./../img/mc.svg" class="img"/>
            <img src="./../img/pci.svg" class="img"/>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-section">
      <div class="wrapper">
        <div class="center">
          <div class="desc desc-main" v-if="settings">©2024{{ settings.copyright }}</div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
	name: 'Footer',
	props: ['settings'],
	data: function() {
		return {
      textPageList: [],
      socialLinks: [],
		}
	},
	methods: {
    getSocialLinks() {
      this.$http.get(process.env.VUE_APP_API + 'social-links')
      .then((res) => {
        this.socialLinks = res.data.payload;
      })
      .catch(() => {});
    },
		getTextPages() {
      this.$http.get(process.env.VUE_APP_API + 'static-pages')
      .then((res) => {
        this.textPageList = res.data.payload
      })
      .catch(() => {
        
      })
    },
    goToPage(item) {
      this.$router.push({path: '/pages/' + item.id, params: {data: item.id}})
      this.$emit('getTextPageContent', item.id);
    }
	},
	mounted() {
    this.getSocialLinks();
    this.getTextPages();
    
  }
}
</script>
