<template>
  <main class="main page-inside products-page categories-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <img src="./../img/hero.jpg" class="bg"/>
        <div class="wrapper home">
          <div class="title big">STORE</div>
        </div>
      </div>
      <div class="section store-section">
        <img src="./../img/section6.svg" class="bg"/>
        <div class="wrapper">
          <transition name="fade">
            <div class="category-list" v-if="$parent.categories">
              <div :class="['category-list__item title small', {'active': activeCategory == item}]" 
                v-for="(item, i) in $parent.categories" 
                :key="i"
                @click="$emit('chooseCategoty', item)"
                v-html="$parent.wrapFirstLetterInSpan(item.title)"
              />
            </div>
          </transition>
          <transition name="fade">
            <div class="product-list-container">
              <div class="list products" v-if="products && products.length">
                <div class="item" v-for="(item, i) in products" :key="i">
                  <ProductCard :item="item" :addToCartChosenItem="addToCartChosenItem" @goToProduct="goToProduct" :currency="currency" @addToCart="addToCart"/>
                </div>
              </div>
            </div>  
          </transition>
        </div>
      </div>
      <div class="section store-section-2">
        <img src="./../img/section7.png" class="bg"/>
        <div class="wrapper">
          <div class="title small server-status"><span>S</span>ERVER <span>S</span>TATUS: <span class="green">ONLINE</span></div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import ProductCard from '../components/ProductCard.vue';
export default {
  name: 'Categories',
  props: ['addToCartChosenItem', 'currency', 'activeCategory'],
  components: {
    ProductCard
  },
  data: function() {
    return {
      imgDomain: '',
      products: []
    }
  },
  watch: {
    activeCategory: function() {
      this.getProducts();
    },
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getProducts();
  },
  methods: {
    getProducts() {
      this.$http.get(process.env.VUE_APP_API + 'products/?category_id=' + this.activeCategory.id)
      .then((res) => {
        this.products = res.data.payload;
      })
      .catch(() => {
         
      })
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
  }
}
</script>