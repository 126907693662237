<template>
  <main class="main page-inside text-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <img src="./../img/hero.jpg" class="bg"/>
        <div class="wrapper home">
          <div class="title big" v-html="$parent.textPageTitle"/>
        </div>
      </div>
      <section class="section store-section">
        <img src="./../img/section6.svg" class="bg"/>
        <transition name="fade">
          <div class="wrapper" v-if="$parent.textPageTitle">
            <div class="content">
              <div class="desc" v-html="$parent.textPageContent"></div>
            </div>
          </div>
        </transition>
      </section>
      <div class="section store-section-2">
        <img src="./../img/section7.png" class="bg"/>
        <div class="wrapper">
          <div class="title small server-status"><span>S</span>ERVER <span>S</span>TATUS: <span class="green">ONLINE</span></div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'TextPage',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  mounted() {
    this.$parent.getTextPageContent(this.$route.params.id);
  },
  methods: {
    
  }
}
</script>