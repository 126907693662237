<template>
  <main class="main page-inside text-page sign-modal reg-modal contact-us-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <img src="./../img/hero.jpg" class="bg"/>
        <div class="wrapper home">
          <div class="title big">CONTACTS</div>
        </div>
      </div>
      <div class="section store-section contacts-section">
        <img src="./../img/section6.svg" class="bg"/>
        <div class="wrapper container">
          <div class="content form-fields form-fields-wrapper">
            <label>
              <div class="desc">Name:</div>
              <input type="text" placeholder="Name" v-model="name"/>
            </label>
            <label>
              <div class="desc">Email:</div>
              <input type="text" placeholder="Email" v-model="email"/>
            </label>
            <label>
              <div class="desc">Message</div>
              <textarea placeholder="Message" v-model="message"></textarea>
            </label>
            <button class="button" @click="submit">Send</button>
            <transition name="fade">
              <div v-if="$parent.successMessage" class="desc small green">{{$parent.successMessage}}</div>
            </transition>
            <transition name="slide">
              <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
            </transition>
          </div>
          <div class="info">
            <div class="info-wrapper">
              <div class="desc">
                {{ $parent.settings && $parent.settings.requisites ? $parent.settings.requisites : ''}}
              </div>
              <div class="desc link">
                {{ $parent.settings && $parent.settings.support_email ? $parent.settings.support_email : ''}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section store-section-2">
        <img src="./../img/section7.png" class="bg"/>
        <div class="wrapper">
          <div class="title small server-status"><span>S</span>ERVER <span>S</span>TATUS: <span class="green">ONLINE</span></div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'ContactUs',
  props: [],
  components: {
    
  },
  data: function() {
    return {
      name: '',
      email: '',
      message: ''
    }
  },
  methods: {
    submit() {
      let data = {
        "name": this.name,
        "email": this.email,
        "message": this.message
      }
      this.$emit('sendContactForm', data);
    },
  }
}
</script>